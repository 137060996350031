<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-card>
          <v-card-text>
            <v-row>
              <v-col
                sm="6"
                cols="12"
              >
                  <v-text-field
                      dense
                      v-model="reqParams.name"
                      label="Name"
                      prepend-inner-icon="mdi-account"
                      :rules="RequiredRules"
                  ></v-text-field>
              </v-col>
              <v-col
                  sm="6"
                  cols="12"
              >
                <v-text-field
                    dense
                    v-model="reqParams.email"
                    sm="12"
                    cols="6"
                    label="Email"
                    prepend-inner-icon="mdi-email"
                    :rules="[emailRules.required, emailRules.email]"
                ></v-text-field>
              </v-col>
              <v-col
                  sm="6"
                  cols="12"
              >
                <v-text-field
                    dense
                    v-model="reqParams.phone_no"
                    sm="12"
                    cols="6"
                    label="Phone Number"
                    prepend-inner-icon="mdi-cellphone"
                    :rules="RequiredRules"
                ></v-text-field>
              </v-col>
              <v-col
                  sm="6"
                  cols="12"
              >
                <v-text-field
                    dense
                    v-model="reqParams.identity_no"
                    sm="12"
                    cols="6"
                    label="Identity Number"
                    prepend-inner-icon="mdi-account-box"
                ></v-text-field>
              </v-col>

              <v-col
                  sm="6"
                  cols="12"
              >
                <v-text-field
                    dense
                    v-model="reqParams.password"
                    sm="12"
                    cols="6"
                    label="Password"
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    :rules="passwordRules"
                ></v-text-field>
              </v-col>
              <v-col
                  sm="6"
                  cols="12"
              >
                <v-text-field
                    dense
                    v-model="reqParams.confirm_password"
                    sm="12"
                    cols="6"
                    label="Confirm Password"
                    type="password"
                    prepend-inner-icon="mdi-lock"
                    :rules="[passwordConfirmationRule]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid"
              color="success"
              @click="saveUserData"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-col>

      <v-overlay
          :absolute="absolute"
          :value="overlay"
      >
        <v-card color="white">
          <v-card-text>
            <overlay-img></overlay-img>
          </v-card-text>
          </v-card>
      </v-overlay>

    </v-row>
  </v-container>
</template>

<script>
import RowHeader from "@/components/content/RowHeader";
import OverlayImg from "@/components/content/OverlayImg";

export default {
  name: 'UserNew',
  components: {
    RowHeader,
    OverlayImg
  },
  data: () => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Create User'},
    roles: [
      {value: 'SuperUser', text: 'Super User'},
      {value: 'Agen', text: 'Agen'},
      {value: 'Loket', text: 'Loket'},
    ],
    valid:true,
    absolute: false,
    overlay: false,
    reqParams: {
                  name: null,
                  email: null,
                  phone_no: null,
                  identity_no: null,
                  password: null,
                  confirm_password: null
                },
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
    emailRules: {
      required: value => !!value || "Email required",
      email: value => {
        const patern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        return patern.test(value) || "Invalid Email"
      },
    },
    passwordRules: [
      (value) => !!value || 'Field cannot empty.',
      (value) => (value && value.length >= 6) || 'minimum 6 characters.',
    ],
  }),
  async mounted() {
    console.log('view page admin user new');
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.reqParams.password === this.reqParams.confirm_password) || 'Password must match'
    }
  },
  methods : {
    saveUserData() {
      this.overlay = true;
      this.$store.getters.currentToken.headers.KeyDesk = this.$store.getters.currentUser.user.desk_login;
      this.$http.post(this.$baseUrl + 'users/create-locket-by-agent', this.reqParams, this.$store.getters.currentToken)
      .then((response) => {
        // console.log(response.data);
        if (response.data) {
          if (response.data.resultCd === '0000') {
            alert('Success create new user');
            this.$router.push({name: 'UserLocketList'})
          }
        }
        this.overlay = false;
      })
      .catch(e => {
        console.log(e.response);
        alert(e.response.data.message)
        this.overlay = false;
      });
    },
  },
}
</script>

<style scoped>

</style>
